import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import logo1 from '@/assets/img/logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Home');
  const mobileMenuRef = useRef(null);
  const location = useLocation();

  const items = ['Home', 'About', 'Academics', 'Contact', 'Blog', 'Gallery'];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigate = (item) => {
    setActiveItem(item);
    setIsMobileMenuOpen(false); // Close the menu when navigating
  };

  const handleClickOutside = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Close the menu when the route changes
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <header className="fixed top-0 left-0 w-full z-50">
      <div className="max-w-7xl mx-auto">
        <nav className="flex items-center justify-between h-16 bg-gray-600 bg-opacity-50 backdrop-blur-lg shadow-sm rounded-full p-4 mx-4 mt-4">
          <Link to="/">
            <img className="w-10 h-10" src={logo1} alt="aps" />
          </Link>

          <div className="hidden sm:flex space-x-4 text-lg mx-auto mr-8">
            <div className="relative flex items-center gap-3">
              <div className="relative py-1">
                <div
                  style={{
                    '--size': `${activeItem.length * 10}px`,
                    '--position': `${items.indexOf(activeItem) * 88}px`,
                    '--duration': '300ms',
                  }}
                  className={clsx(
                    'absolute bottom-0 left-0 h-1 w-[--size] translate-x-[--position] rounded-full bg-white/25 transition-[width,transform] duration-[--duration]'
                  )}
                />
                <ul className="relative flex items-stretch gap-3">
                  {items.map((item, index) => (
                    <li key={index} onClick={() => navigate(item)}>
                      <Link
                        to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                        className={clsx(
                          item === activeItem ? 'text-white' : 'text-white/60 hover:text-white',
                          'inline-block p-4 text-sm transition'
                        )}
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <button className="hidden sm:block font-semibold text-white bg-[#000C66] hover:bg-[#FAD02C] hover:text-white hover:ease-in duration-500 px-4 py-2 rounded-full ml-auto hover:duration-700">
            <Link to="/add-1">
              Enroll Now
            </Link>
          </button>
          <div className="sm:hidden flex items-center">
            <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </nav>
        <div
          ref={mobileMenuRef}
          className={clsx(
            'sm:hidden bg-white backdrop-blur-lg shadow-sm rounded-lg mx-4 mt-2 ease-out duration-200 rounded-md',
            isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          )}
          style={{ transition: 'max-height 0.3s ease, opacity 0.3s ease' }}
        >
          <ul className="space-y-4 mt-4 text-lg p-4">
            {items.map((item) => (
              <li key={item} onClick={() => navigate(item)}>
                <Link
                  to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                  className="block text-black hover:text-gray-700 hover:ease-in duration-100"
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
