import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import ScrollToTop from './Components/ScrollToTop';
import Spinner from './Components/Spinner';
import Lenis from 'lenis';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AdmissionForm from './Components/EnrollNow/Form1';

// Lazy load the components
const Hero = lazy(() => import('./Components/Hero'));
const Features = lazy(() => import('./Components/Features'));
const Welcome = lazy(() => import('./Components/Welcome'));
const Cards2 = lazy(() => import('./Components/Cards2'));
const CapturePicturesWithAI = lazy(() => import('./Components/InfiniteScroll'));
const Name2 = lazy(() => import('./Components/Name2'));
const Testi = lazy(() => import('./Components/Testi'));
const Info2 = lazy(() => import('./Components/Info2'));
const Contacts = lazy(() => import('./Components/Contacts'));
const Footer1 = lazy(() => import('./Components/Footer1'));
const About = lazy(() => import('./Components/pages/About'));
const Gallery = lazy(() => import('./Components/Gallery/Gallery'));
const Info3 = lazy(() => import('./Components/pages/Info3'));
const Academics = lazy(() => import('./Components/pages/Academics/Academics'));
const Blog = lazy(() => import('./Components/pages/Blog/Blog'));
const BlogPost1 = lazy(() => import('./Components/pages/Blog/BlogPost1'));
const BlogPost2 = lazy(() => import('./Components/pages/Blog/BlogPost2'));
const BlogPost3 = lazy(() => import('./Components/pages/Blog/BlogPost3'));
const BlogPost4 = lazy(() => import('./Components/pages/Blog/BlogPost4'));
const BlogPost5 = lazy(() => import('./Components/pages/Blog/BlogPost5'));
const BlogPost6 = lazy(() => import('./Components/pages/Blog/BlogPost6'));
const BlogPost7 = lazy(() => import('./Components/pages/Blog/BlogPost7'));
const BlogPost8 = lazy(() => import('./Components/pages/Blog/BlogPost8'));
const BlogPost9 = lazy(() => import('./Components/pages/Blog/BlogPost9'));
const Form1 = lazy(() => import('./Components/EnrollNow/Form1'));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const lenis = new Lenis();

    // Optional: listen to scroll events
    lenis.on('scroll', (e) => {
      console.log(e);
    });

    // Animation frame loop
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // Simulate a loading delay for demonstration purposes
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    // Cleanup function
    return () => {
      lenis.destroy();
      cancelAnimationFrame(raf);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      AOS.init({
        duration: 2000,
        once: true,
      });
      AOS.refresh();
    }
  }, [loading]);

  return (
    <>
      {loading && <Spinner />}
      <div className={loading ? 'hidden' : 'block'}>
        <Router>
          <Navbar />
          <ScrollToTop />
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={
                <>
                  <Hero />
                  <Welcome />
                  <Features />
                  <Cards2 />
                  <CapturePicturesWithAI />
                  <Name2 />
                  <Testi />
                  <Info2 />
                  <Contacts />
                  <Footer1 />
                </>
              } />
              <Route path="/about" element={<About />} />
              <Route path="/Gallery" element={<Gallery />} />
              <Route path="/Contact" element={<Info3 />} />
              <Route path="/Academics" element={<Academics />} />
              <Route path="/Blog" element={<Blog />} />
              <Route path="/blog-post-1" element={<BlogPost1 />} />
              <Route path="/blog-post-2" element={<BlogPost2 />} />
              <Route path="/blog-post-3" element={<BlogPost3 />} />
              <Route path="/blog-post-4" element={<BlogPost4 />} />
              <Route path="/blog-post-5" element={<BlogPost5 />} />
              <Route path="/blog-post-6" element={<BlogPost6 />} />
              <Route path="/blog-post-7" element={<BlogPost7 />} />
              <Route path="/blog-post-8" element={<BlogPost8 />} />
              <Route path="/blog-post-9" element={<BlogPost9 />} />
              <Route path="/add-1" element={<Form1 />} />
              {/* Add more routes as needed */}
            </Routes>
          </Suspense>
        </Router>
      </div>
    </>
  );
}

export default App;
